<template>
  <div>
    <div>
      <sz-input v-bind="$attrs" class="szInput"> </sz-input>
      <span class="set" @click="addVisible = true">
        <i class="iconfont iconxuanze"></i>
      </span>
      <base-dialog
        :visible="addVisible"
        width="1100px"
        :showFooter="false"
        @close="addVisible = false"
        class="addPersonal"
        title="账户信息"
      >
        <p>
          若无账号信息，可点击<span class="link" @click="addPersonal">此处</span
          >进行新增！
        </p>
        <base-form
          :componentList="formConfig"
          :formAttrs="{
            model: queryParas,
            labelWidth: '90px',
          }"
          class="formStyle"
          @handleFilter="getList"
          @clearParams="clearParams"
          ref="formData"
          v-show="false"
        >
        </base-form>
        <base-table
          :columns="columns"
          :showPage="true"
          :tableAttrs="{
            data: tableData,
            stripe: true,
          }"
          :dataSource.sync="tableData"
          @pageIndexChange="pageIndexChange"
          @pageSizeChange="pageSizeChange"
          :webPage="true"
          ref="tableData"
          :isCaculateHeight="false"
        >
          <template slot="radio" slot-scope="scope">
            <el-radio
              v-model="radioArr[scope.$index]"
              @change="change(scope.$index)"
              :label="true"
              >&nbsp;</el-radio
            >
          </template>
        </base-table>
        <template slot="footer">
          <base-button label="确认" @click="batchSave"></base-button>
          <base-button
            label="关 闭"
            type="default"
            @click="addVisible = false"
          ></base-button>
        </template>
        <!-- 新增账号 -->

        <base-dialog
          :visible="addCountVisible"
          width="1100px"
          :showFooter="false"
          @close="addCountVisible = false"
          class="addPersonal"
          title="新增账户信息"
        >
          <div style="text-align: right; margin-bottom: 10px">
          <base-button
            label="新增"
            @click="
              addTableData.push({enterpriseId:$attrs.gysId })
            "
          ></base-button>
        </div>
          <base-table
            :columns="addColumns"
            :showPage="false"
            :tableAttrs="{
              data: addTableData,
              stripe: true,
            }"
            :dataSource.sync="tableData"
            :isCaculateHeight="false"
            class="addForm"
          >
            <template slot="account" slot-scope="scope">
              <el-input
                maxlength="50"
                placeholder="请输入"
                @input="
                  scope.row.account = scope.row.account.replace(
                    /[^\a-\z\A-\Z\u4E00-\u9FA5\.\,\?\<\>\。\，\-\_\——\=\;\@\！\!\+\$]/g,
                    ''
                  )
                "
                @blur="clear"
                v-model="scope.row.account"
              ></el-input>
            </template>
            <template slot="bankName" slot-scope="scope">
              <el-input
                maxlength="50"
                placeholder="请输入"
                @input="
                  scope.row.bankName = scope.row.bankName.replace(
                    /[^\a-\z\A-\Z\u4E00-\u9FA5\.\,\?\<\>\。\，\-\_\——\=\;\@\！\!\+\$]/g,
                    ''
                  )
                "
                @blur="clear"
                v-model="scope.row.bankName"
              ></el-input>
            </template>
            <template slot="accountNo" slot-scope="scope">
              <el-input
                maxlength="30"
                placeholder="请输入"
                @input="
                  scope.row.accountNo = scope.row.accountNo.replace(
                    /[^\d]/g,
                    ''
                  )
                "
                @blur="clear"
                v-model="scope.row.accountNo"
              ></el-input>
            </template>
            <template slot="accountType" slot-scope="scope">
              <base-select
                v-model="scope.row.accountType"
                placeholder="请选择"
                :options="getDictLists('SUPPLIER_BANK_TYPE')"
                :selectedField="['dictId', 'dictName']"
                class="addFormSelect"
              ></base-select>
            </template>
            <template slot="action" slot-scope="scope">
                <IconButton
                  content="删除"
                  @click="remove(scope.$index)"
                  icon="iconfont iconshanchu1"
                ></IconButton>
            </template>
          </base-table>
          <template slot="footer">
            <base-button label="确认" @click="batchAddSave"></base-button>
            <base-button
              label="关 闭"
              type="default"
              @click="addCountVisible = false"
            ></base-button>
          </template>
        </base-dialog>
      </base-dialog>
    </div>
  </div>
</template>

<script>
import { getDictLists } from '@/filters/fromDict'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import SzInput from '@/components/input/sz-input/sz-input.vue'
import { selectAccountColumns, addAccountColumns } from '../utils/closed-loop-info-config'
import { supplierApi } from '@/api/companyApi'
import BaseForm from '@/components/common/base-form/base-form.vue'
export default {
  components: {
    SzInput,
    BaseDialog,
    BaseTable,
    BaseButton,
    BaseForm,
    BaseSelect,
    IconButton
  },
  props: {},
  data () {
    return {
      addTableData: [],
      addCountVisible: false,
      radioArr: [],
      addVisible: false,
      tableData: [],
      queryParas: { pageIndex: 1, pageSize: 10 },
      formConfig: [
        {
          label: '账户名',
          prop: 'businessNo',
          attrs: {
            placeholder: '请输入',
            maxlength: 20
          }
        }
      ]
    }
  },
  // 计算属性 类似于data概念
  computed: {
    api () {
      return supplierApi
    },
    columns () {
      return selectAccountColumns(this)
    },
    addColumns () {
      return addAccountColumns(this)
    },
    getDictLists () {
      return getDictLists
    }
  },
  // 监控data中的数据变化
  watch: {
    addTableData: {
      handler (val) {
        this.$emit('clearFormValidate', '')
      },
      deep: true
    },
    addVisible (val) {
      if (val) {
        this.getList()
      }
    }
  },
  // 方法集合
  methods: {
    clear () {
      this.$emit('clearFormValidate', '')
    },
    // 删除
    remove (index) {
      this.addTableData.splice(index, 1)
    },
    // 新增保存
    batchAddSave () {
      let pass = true
      this.addTableData.forEach((item) => {
        if (!item.account || !item.bankName || !item.accountNo || !item.accountType) {
          pass = false
        }
      })
      if (!pass) {
        this.warning('请完善信息')
        return false
      }
      this.api.AccountAdd(this.addTableData).then(res => {
        if (res.data) {
          this.success('添加成功')
          this.addCountVisible = false
          this.getList()
        }
      })
    },
    addPersonal () {
      this.addTableData = []
      this.addCountVisible = true
    },
    clearParams () {
      this.queryParas.pageIndex = 1
      this.queryParas.pageSize = 10
    },
    // 获取账户数据
    getList () {
      const params = {
        enterpriseId: this.$attrs.gysId,
        accountTypes: this.$attrs.accountTypes,
        ...this.queryParas
      }
      this.radioArr = []
      // const rowData = this.$attrs.rowData
      this.api.Accountpage(params).then((res) => {
        this.tableData = res.data.records
        this.tableData.forEach((item) => {
          // if (item.account === rowData.account && item.keyId === rowData.keyId && item.accountNo === rowData.accountNo && item.bankName === rowData.bankName) {
          //   this.radioArr.push(true)
          // } else {
          //   this.radioArr.push(false)
          // }
        })
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$refs.tableData.total = res.data.total
          })
        })
      })
    },
    // 提交账户
    batchSave () {
      let pass = false
      let i = ''
      this.radioArr.forEach((item, index) => {
        if (item) {
          pass = true
          i = index
        }
      })
      if (!pass) {
        this.warning('请选择账户')
        return false
      }
      const value = this.tableData[i]
      this.$emit('selectAccount', value)
      this.addVisible = false
    },
    pageIndexChange (item) {
      this.queryParas.pageIndex = item
      this.getList()
    },
    pageSizeChange (item) {
      this.queryParas.pageSize = item
      this.queryParas.pageIndex = 1
      this.getList()
    },
    change (i) {
      this.radioArr.forEach((item, index) => {
        if (index === i) {
          // this.$set(this.radioArr, index, !item)
        } else {
          if (item) {
            this.$set(this.radioArr, index, !item)
          }
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.link {
  color: rgb(53, 121, 248);
  text-decoration: underline;
  cursor: pointer;
}
.addFormSelect{
  /deep/ .el-input__validateIcon{
    display: none;
  }
}
/deep/ .el-select {
  .el-icon-circle-close {
    display: none;
  }

}

.addPersonal /deep/.el-dialog {
  height: 78vh !important;
}
.szInput {
  width: calc(100% - 45px) !important;
}
.set {
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  display: inline-block;
  background: linear-gradient(90deg, #285ED7 0%, #749EFF 100%);
  border-radius: 50%;
}
</style>
